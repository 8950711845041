.carousel {
  background-color: #40ab4e;
  height: 100vh;
}

.carousel-item {
  height: 100vh;
}

.carousel-caption {
  bottom: 30% !important;
  z-index: 2;
}

.white-border {
  border-color: #ffffff !important;
  color: #ffffff !important;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1;
}

@media (max-width: 575.98px) {
  .mobileView {
    width: 100vw;
    height: 100vh;
  }
  .smallDevice {
    width: 100vh;
  }
  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .flex-item {
    margin-bottom: 10px;
  }
}

.mobileView {
  width: 100%;
  max-width: 100%;
  height: auto;
  text-align: center;
  object-position: center;
}

.smallDevice {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  object-fit: cover; /* Ensure the entire image is visible */
  object-position: center;
}
@media (min-width: 767.98px) {
  .smallDevice {
    width: 100%;
  }
}
