.outline-circle{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 2px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom{
    border-bottom: 2px solid #ebebeb;
}

.title{
    margin-bottom: 10px;
    color: #40ab4e
}

.negative-bottom{
    margin-bottom: -3px;
}


@media (max-width: 575.98px) { 

 }

@media (min-width: 576px) {
    .section-height{
        height: 100vh;
    }
    
}