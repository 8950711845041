/* styles.css */

/* Style the MenuIcon */
.navbar-toggler-icon {
  color: #333; /* Color of the MenuIcon */
  font-size: 24px; /* Adjust the font size */
}

a.nav-link:hover {
  border-bottom: 2px solid #40ab4e;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  color: #40ab4e;
}

/* Media query for screens smaller than LG (breakpoint for lg) */
@media (max-width: 992px) {
  /* Center the Navbar.Brand */
  .navbar-brand {
    text-align: center;
  }

  /* Add margin to the Navbar.Brand for spacing */
  .navbar-brand img {
    margin-right: 15px;
  }

  /* Adjust the spacing between Nav Links */
  .d-flex.gap-5 .nav-link {
    margin-right: 10px;
  }
}
