/* style.css */

/* Default styles for larger screens */
.how-section {
  width: 100vw;
  height: 70vh;
  background-color: #40ab4e;
}

.how-inn {
  background-color: #32933e;
  margin-left: 15vw;
  margin-right: 15vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  height: 400px; /* Fixed height for .how-inn */
}

.green-pill {
  background-color: #40ab4e !important;
  border-color: #40ab4e !important;
}

/* Default text styles */
.our_bilive {
  color: #fff;
  font-size: 25px;
  font-weight: 40px;
  margin-bottom: 20px;
}

.bosskids {
  color: #fff;
  font-size: 40px;
  font-weight: 40px;
  margin-bottom: 20px;
}

/* Responsive styles for smaller screens (max-width: 767px) */
@media (max-width: 767px) {
  .how-inn {
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 20px;
  }
  .bosskids {
    font-size: 30px; /* Adjust font size for smaller screens */
  }
  .our_bilive {
    font-size: 18px; /* Adjust font size for smaller screens */
  }
}

/* Responsive styles for extra small screens (max-width: 575px) */
@media (max-width: 575px) {
  .how-inn {
    margin-left: 3vw;
    margin-right: 3vw;
    padding: 10px;
  }
  .bosskids {
    font-size: 24px; /* Adjust font size for extra small screens */
  }
  .our_bilive {
    font-size: 14px; /* Adjust font size for extra small screens */
    text-align: left;
    align-items: center;
  }
  /* Add additional responsive styles for extra small screens as needed */
}

/* Common styles for both small and extra small screens */
.smallDevice {
  width: 100%;
}

.mobileView {
  width: 100vw;
  height: 100vh;
}
