.spacer {
  margin-top: 100px;
}

.iconbg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e7f4e8;
  justify-content: center;
  align-items: center;
  display: flex;
}

.arts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.art {
  border: 1px #e7f4e8;
  margin: 2rem;
}

.art img {
  width: 100%;
  height: 100%;
  max-width: 300px;
}

.buy {
  border: 1px solid #1bc429;
  background-color: #1bc429;
  color: white;
  border-radius: 15px;
  margin-top: 1rem;
}
@media (max-width: 575.98px) {
  .mobileView {
    width: 100vw;
    height: 100vh;
  }
  .smallDevice {
    width: 100vh;
  }
}
